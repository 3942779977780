import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import COLOR from "../assets/js/Color";
import API from "../api";
import { useLocation } from "react-router-dom";

let api = new API();

export default function GoalprizeInformationDetailView(props) {
  const { state } = useLocation();

  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(null);

  useEffect(() => {
    if (state.from === "gts") {
      getTermsOfService();
    } else if (state.from === "howToWin") {
      getHowToWin();
    } else if (state.from === "fqe") {
      getFaq();
    } else if (state.from === "privacy") {
      getPrivacyPolicy();
    } else if (state.from === "refund") {
      getRefundAndDataPolicy();
    } else if (state.from === "partners") {
      getBusinessPartner();
    }
  }, []);

  function getTermsOfService() {
    api
      .getTermsCondition()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  }

  function getHowToWin() {
    api
      .getHowToWin()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }

  function getFaq() {
    api
      .getFaq()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }

  function getPrivacyPolicy() {
    api
      .getPolicy()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }

  function getRefundAndDataPolicy() {
    api
      .getRefundAndDataPolicy()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }

  function getBusinessPartner() {
    api
      .getBusinessPartner()
      .then((res) => {
        if (res.data.status === 200) {
          setSelectedTab(res.data.response.text);
          setSelectedTitle(state.name);
        } else if (res.status === 400) {
          console.log(res);
        } else {
          console.log(res);
        }
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
        });
        console.log(error);
      });
  }

  function createMarkup(props) {
    return { __html: props };
  }

  return (
    <>
      <Grid container justifyContent={"center"}>
        <Card
          sx={{
            width: {
              xl: "60%",
              lg: "60%",
              md: "90%",
              sm: "90%",
              xs: "100%",
            },
          }}
        >
          {/* <CardHeader
            title={selectedTitle}
            align="center"
            disableTypography={true}
            sx={styles.cardTitleText}
          /> */}
          <CardContent>
            <div dangerouslySetInnerHTML={createMarkup(selectedTab)} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      lg: 25,
      md: 25,
      sm: 28,
      xs: 18,
    },
    fontFamily: "Helvetica Neue",
    color: COLOR.BLACK,
  },

  cardBodyTextUnderline: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
    paddingRight: 5,
    textDecoration: "underline",
  },
  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
    paddingRight: 5,
  },
  cardTitileTextBox: {
    backgroundColor: COLOR.GREEN,
    borderRadius: 10,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 10,
    margin: 10,
  },
};
