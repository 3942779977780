"use strict";

const IMAGES = {
  logo: "/assets/images/logo.png",
  foodType: require("../../assets/img/restaurant.png"),
  address: require("../../assets/img/address.png"),
  id: require("../../assets/img/id.png"),
  phone: require("../../assets/img/phone.png"),
  special: require("../../assets/img/special.png"),
  time: require("../../assets/img/time.png"),
  website: require("../../assets/img/website.png"),
};

export default IMAGES;
