import axios from "axios";
import {
  U_TERMS_AND_CONDTION,
  U_PRIVACY_POLICY,
  U_REFUND_POLICY,
  U_HOW_TO_WIN,
  U_FAQ,
  U_BUSINESS_PARTNER,
  U_BASE,
  // AS_USER_TOKEN,
  U_LIST_ALL_CURRENT_CAMPAIGNS,
  U_LIST_ALL_PAST_CAMPAIGNS,
  U_LIST_ALL_FUTURE_CAMPAIGNS,
  //   AS_APP_LANGUAGE,
  U_LEADERBOARD,
} from "../commonView/Constants";

/* const headers = async () => {
   const headers = {
    "Content-Type": "application/json",
  }; 
     const auth = new Auth();
     const token = await auth.getValue(AS_USER_TOKEN);
     const lang = await auth.getValue(AS_APP_LANGUAGE);
   await console.log('user token is:-', token);
    if (token) {
       headers.Authorization = `Bearer ${token}`;
     }
     headers.lang = lang;
     return headers;
}; */

const request = async (method, path, body) => {
  const url = `${U_BASE}${path}`;
  const options = { method, url, headers: {} };
  if (body) {
    options.data = body;
  }
  // console.log("options are:-", options, body);

  return axios(options);
};

export default class API {
  getTermsCondition(data) {
    return request("POST", U_TERMS_AND_CONDTION, data);
  }
  getPolicy(data) {
    return request("POST", U_PRIVACY_POLICY, data);
  }
  getRefundAndDataPolicy(data) {
    return request("POST", U_REFUND_POLICY, data);
  }
  getHowToWin() {
    return request("POST", U_HOW_TO_WIN);
  }
  getFaq() {
    return request("POST", U_FAQ);
  }
  getBusinessPartner() {
    return request("POST", U_BUSINESS_PARTNER);
  }
  getAllCurrentCampaigns() {
    return request("GET", U_LIST_ALL_CURRENT_CAMPAIGNS);
  }
  getAllPastCampaigns() {
    return request("GET", U_LIST_ALL_PAST_CAMPAIGNS);
  }
  getAllFutureCampaigns() {
    return request("GET", U_LIST_ALL_FUTURE_CAMPAIGNS);
  }
  getLeaderboardData(data) {
    return request("GET", U_LEADERBOARD + data);
  }
}
