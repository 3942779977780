import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import COLOR from "../assets/js/Color";
import { useNavigate, useLocation } from "react-router-dom";
import { getDateFromTimeStamp } from "../commonView/Helpers";

const TextButton = (props) => {
  const navigate = useNavigate();

  function clickTextButton() {
    navigate("/goalprizeInformationDetailView", {
      state: { name: props.name, from: props.from },
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Typography sx={styles.cardBodyTextUnderline} onClick={clickTextButton}>
      {props.name}
    </Typography>
  );
};

const DetailView = (props) => {
  const { state } = useLocation();

  return (
    <Grid
      container
      direction={{
        xl: "column",
        lg: "column",
        md: "column",
        sm: "row",
        xs: "row",
      }}
      style={{
        backgroundColor: "#FFE600",
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 20,
      }}
    >
      <Typography
        sx={styles.cardBodyText}
        style={{ marginTop: 20, marginLeft: 20 }}
      >
        <strong>Rules of this Goalprize campaign</strong>
      </Typography>
      <Stack
        spacing={{ lg: 3, md: 3, sm: 3, xs: 2 }}
        style={{ marginTop: 20, marginLeft: 20, marginBottom: 20 }}
      >
        <Typography sx={styles.cardBodyText}>
          <strong>Restaurant:</strong>
          <br />
          {state?.trading?.name}
          <br />
          {state?.trading?.address}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Start Date and Time ("Start"):</strong>
          <br />
          {getDateFromTimeStamp(state?.startTime, "MMMM DD, YYYY hh:mm a")}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>End Date and Time ("End"):</strong>
          <br />
          {getDateFromTimeStamp(state?.endTime, "MMMM DD, YYYY hh:mm a")}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Goal: </strong>
          {state?.goal} customer visits between Start and End spending more than
          minimum.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>The maximum prize is 50,000 THB</strong>
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Minimum spent to count a visitor: </strong>
          <br />
          {state?.minSpent} THB per visitor on same paid receipt.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Winners: </strong>
          Top {state?.winners}% ranked accounts on Leaderboard if this Goalprize
          achieves Goal before End. Accounts ranked by points earned from
          spending on approved items like food, not alcohol, not tax, not
          service charge.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Prize: </strong>
          Digital gift card for that restaurant.
          <br />
          Every point in winning account = 1 credit.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Credit Expiration: </strong>
          <br />
          {getDateFromTimeStamp(
            state?.campaignTemplate.creditExpiryDateTime,
            "MMMM DD, YYYY hh:mm a"
          )}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Business ID: </strong>
          <br />
          {state?.trading?.biz_id}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Single Restuarant Location ID: </strong>
          <br />
          {state?.trading?.srl_id}
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Goalprize Campaign ID: </strong>
          <br />
          {state?.system_id}
        </Typography>
      </Stack>
    </Grid>
  );
};

const NoDetailView = (props) => {
  return (
    <Grid
      container
      direction={{
        xl: "column",
        lg: "column",
        md: "column",
        sm: "row",
        xs: "row",
      }}
      style={{
        backgroundColor: "#FFE600",
        borderRadius: 10,
        marginTop: 20,
        paddingLeft: 20,
      }}
    >
      <Typography
        sx={styles.cardBodyText}
        style={{ marginTop: 20, marginLeft: 20 }}
      >
        <strong>Example of a Goalprize</strong>
      </Typography>
      <Stack
        spacing={{ lg: 3, md: 3, sm: 3, xs: 2 }}
        style={{ marginTop: 20, marginLeft: 20, marginBottom: 20 }}
      >
        <Typography sx={styles.cardBodyText}>
          <strong>Restaurant:</strong>
          <br />
          (Restaurant name goes here)
          <br />
          (Address goes here)
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Start Date and Time ("Start"):</strong>
          <br />
          September 1, 2022 10:00 AM
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>End Date and Time ("End"):</strong>
          <br />
          September 30, 2022 07:59 PM
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Goal: </strong>
          1,000 customer visits between Start and End spending more than
          minimum.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Minimum spent to count a visitor: </strong>
          <br />
          100 THB per visitor on same paid receipt.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Winners: </strong>
          Top 50% ranked accounts on Leaderboard if this Goalprize achieves Goal
          before End. Accounts ranked by points earned from spending on approved
          items like food, not alcohol, not tax, not service charge.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Prize: </strong>
          Digital gift card for that restaurant.
          <br />
          Every point in winning account = 1 credit.
        </Typography>
        <Typography sx={styles.cardBodyText}>
          <strong>Credit Expiration: </strong>7 days after campaign ends
          successfully(achieves goal before End).
        </Typography>
      </Stack>
    </Grid>
  );
};

export default function GoalprizeInformationView(props) {
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Card
          sx={{
            width: {
              xl: "60%",
              lg: "60%",
              md: "90%",
              sm: "90%",
              xs: "100%",
            },
          }}
        >
          <CardHeader
            title="Goalprize Information"
            align="center"
            disableTypography={true}
            sx={styles.cardTitleText}
          />
          <CardContent>
            <Stack spacing={{ lg: 3, md: 3, sm: 3, xs: 2 }}>
              <TextButton
                name={'Goalprize Terms of Service ("GTS")'}
                from={"gts"}
              />
              <TextButton name={"How to Participate"} from={"howToWin"} />
              <TextButton name={"Frequently Asked Questions"} from={"fqe"} />
              <TextButton name={"Goalprize Data Policy"} from={"privacy"} />
              <TextButton name={"Goalprize Refund Policy"} from={"refund"} />
              <TextButton
                name={"Seeking Business Partners"}
                from={"partners"}
              />
            </Stack>
            {/* <NoDetailView /> */}
            <DetailView />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      lg: 25,
      md: 25,
      sm: 28,
      xs: 18,
    },
    fontFamily: "Helvetica Neue",
    color: COLOR.BLACK,
  },

  cardBodyTextUnderline: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
    paddingRight: 5,
    textDecoration: "underline",
  },
  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
    paddingRight: 5,
  },
  cardTitileTextBox: {
    backgroundColor: COLOR.GREEN,
    borderRadius: 10,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 10,
    margin: 10,
  },
};
