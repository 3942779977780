import * as React from "react";
import { Routes, Route } from "react-router-dom";
import CampaignDetail from "../views/CampaignDetail";
import HomeView from "../views/HomeView";
import LeaderboardView from "../views/LeaderboardView";
import GoalprizeInformationView from "../views/GoalprizeInformationView";
import GoalprizeInformationDetailView from "../views/GoalprizeInformationDetailView";
import RestaurantDetail from "../views/RestaurantDetail";
// import App from "../App";
// import ErrorPage from "../views/ErrorPage";

export default function RouteList() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeView />} />
        <Route path="campaignDetail" element={<CampaignDetail />} />
        <Route path="/leaderboardView" element={<LeaderboardView />} />
        <Route
          path="/goalprizeInformationView"
          element={<GoalprizeInformationView />}
        />
        <Route
          path="/goalprizeInformationDetailView"
          element={<GoalprizeInformationDetailView />}
        />

        <Route path="restaurantDetail" element={<RestaurantDetail />} />

        {/* <Route path="*" element={<ErrorPage />} /> */}
      </Routes>
    </>
  );
}
