import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ProgressBar from "@ramonak/react-progress-bar";
import COLOR from "../assets/js/Color.js";
import Chip from "@mui/material/Chip";

export default function PastCampaignCard(props) {
  return (
    <>
      <Card sx={styles.mainCard} onClick={props.onClick}>
        {/*  <CardHeader
          title={props.title}
          style={{
            backgroundColor: "black",
          }}
          align="left"
          disableTypography={true}
          sx={[
            styles.cardTitleText,
            {
              maxHeight: {
                lg: 100,
                md: 90,
                sm: 80,
                xs: 0,
              },
            },
          ]}
        /> */}
        <CardContent>
          <Grid container>
            <Grid item>
              <Stack alignItems={"flex-start"}>
                <Typography
                  sx={[
                    styles.cardBodyText,
                    {
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      wordWrap: "break-word",
                      maxWidth: "100%",
                    },
                  ]}
                >
                  {props.title ? props.title : ""}
                </Typography>

                <Typography
                  noWrap
                  sx={[
                    styles.cardBodyText,
                    {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: {
                        xl: "22rem",
                        lg: "22rem",
                        md: "25rem",
                        sm: "22rem",
                        xs: "10rem",
                      },
                    },
                  ]}
                >
                  {props.bizId ? props.bizId : ""}
                </Typography>
                <Typography
                  noWrap
                  sx={[
                    styles.cardBodyText,
                    {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: {
                        xl: "22rem",
                        lg: "22rem",
                        md: "25rem",
                        sm: "22rem",
                        xs: "10rem",
                      },
                    },
                  ]}
                >
                  {props.srlId ? props.srlId : ""}
                </Typography>
                <Typography
                  noWrap
                  sx={[
                    styles.cardBodyText,
                    {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: {
                        xl: "22rem",
                        lg: "22rem",
                        md: "25rem",
                        sm: "22rem",
                        xs: "10rem",
                      },
                    },
                  ]}
                >
                  {props.gpcId ? props.gpcId : ""}
                </Typography>
                {/*  <Typography
                  noWrap
                  sx={[
                    styles.cardBodyText,
                    {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: {
                        xl: "22rem",
                        lg: "22rem",
                        md: "25rem",
                        sm: "22rem",
                        xs: "10rem",
                      },
                    },
                  ]}
                >
                  {props.address}
                </Typography> */}
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} xs={6}>
                    <Typography align="left" sx={styles.cardBodyText}>
                      Start: {props.startTime}
                    </Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} xs={6}>
                    <Typography align="right" sx={styles.cardBodyText}>
                      End: {props.endTime}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography sx={styles.cardBodyText}>
                  Goalprize visits:{" "}
                  {props.currentVisits < props.goal
                    ? props.currentVisits
                    : props.goal}{" "}
                  at {props.endTimeVisits}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: 10, marginBottom: -10 }}
            alignItems="center"
          >
            <Grid item xl={6} lg={6} md={6} xs={6}>
              <Typography align="left" sx={styles.cardBodyText}>
                Goal: {props.goal}
              </Typography>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              xs={6}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {/* props?.data?.status == 4
                    ? Locale("Successful")
                    : props?.data?.status == 5
                    ? Locale("Unsuccessful") */}
              {props.status == 5 ? (
                <Chip
                  label="Unsuccessful"
                  sx={{
                    backgroundColor: COLOR.RED,
                    color: "white",
                    fontSize: styles.cardTitleText.fontSize,
                  }}
                />
              ) : (
                <Chip
                  label="Successful"
                  sx={{
                    backgroundColor: COLOR.GREEN,
                    color: "white",
                    fontSize: styles.cardTitleText.fontSize,
                  }}
                />
              )}
            </Grid>
          </Grid>
          <div style={{ marginTop: 20 }}>
            <ProgressBar
              completed={
                props.completedPercentage > 0 ? props.completedPercentage : "0"
              }
              maxCompleted={100}
              bgColor={"#06C903"}
              labelAlignment={"center"}
              height={"40"}
              baseBgColor={"white"}
              animateOnRender={true}
              transitionTimingFunction={"ease-in"}
            />
          </div>
          <Grid container style={{ marginTop: 10, marginBottom: -10 }}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Typography sx={styles.cardBodyText}>{"Details >"}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      xl: 25,
      lg: 25,
      md: 25,
      sm: 25,
      xs: 16,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 30,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  progressBarText: {
    fontSize: {
      lg: 28,
      md: 30,
      sm: 28,
      xs: 14,
    },
  },

  mainCard: {
    cursor: "pointer",
    backgroundColor: COLOR.GP_BLUE,
  },

  restaurantPhoto: {
    height: {
      lg: 220,
      md: 220,
      sm: 200,
      xs: 110,
    },
    width: { lg: 390, md: 390, sm: 320, xs: 175 },
    borderRadius: 2,
  },
};
