export const U_BASE = "https://api.prod.goalprize.com/";
// export const U_BASE = "https://api.staging.goalprize.com/";

// export const U_BASE = "http://localhost:1337/";
export const U_TERMS_AND_CONDTION = "terms-conditions";
export const U_PRIVACY_POLICY = "data-policy";
export const U_REFUND_POLICY = "rules-details";
export const U_HOW_TO_WIN = "how-to-win";
export const U_FAQ = "faq";
export const U_BUSINESS_PARTNER = "business-partner";
export const AS_USER_TOKEN = "user_access_token";
export const AS_APP_LANGUAGE = "AS_APP_LANGUAGE";
export const U_LIST_ALL_CURRENT_CAMPAIGNS = "current-campaigns-leaderboard";
export const U_LIST_ALL_PAST_CAMPAIGNS = "past-campaigns-leaderboard";
export const U_LIST_ALL_FUTURE_CAMPAIGNS = "future-campaigns-leaderboard";
export const U_LEADERBOARD = "leaderboard/";
export const U_SOCKET_URL = "https://socketapi.prod.goalprize.com";
// export const U_SOCKET_URL = "https://socketapi.staging.goalprize.com";

export const U_SOCKET_EVENT_NAME = "syncLeaderBoard";
