import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import IMAGES from "../assets/js/Images";
import { styled } from "@mui/material/styles";
import COLOR from "../assets/js/Color";
import { getDateFromTimeStamp } from "../commonView/Helpers";
import { useLocation } from "react-router-dom";

const LogoImage = styled("img")({
  width: "70%",
});

function getTimeSlots(state) {
  let campaign = state;
  let mTemp = [];
  const weekName = [
    "SUNDAY",
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
  ]; // Assuming you have weekName defined somewhere

  if (campaign) {
    for (let i = 0; i < campaign.trading.days.length; i++) {
      if (campaign.trading.days[i].length === 0) {
        mTemp.push({ days: weekName[i], time: "CLOSED" });
      }
      for (let j = 0; j < campaign.trading.days[i].length; j++) {
        if (
          campaign.trading.days[i][j] &&
          campaign.trading.days[i][j].is_opened
        ) {
          let timing = `${getDateFromTimeStamp(
            campaign.trading.days[i][j].opening,
            "h:mm a"
          )} - ${getDateFromTimeStamp(
            campaign.trading.days[i][j].closing,
            "h:mm a"
          )}`;
          mTemp.push({ days: weekName[i], time: timing });
        } else {
          mTemp.push({ days: weekName[i], time: "CLOSED" });
        }
      }
    }
  } else {
    mTemp.push(
      { days: "MON-THUR", time: "11 AM TO 9 PM" },
      { days: "FRI-SAT", time: "11 AM TO 11:30 PM" },
      { days: "SUNDAY", time: "9 AM TO 9 PM" }
    );
  }

  return mTemp;
}

const InfoCard = (props) => {
  return (
    <Card
      sx={{
        display: "flex",
        padding: 2,
        alignItems: "center",
        boxShadow: "none",
      }}
    >
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <LogoImage src={props.logoSrc} alt="Logo" />
        </Grid>
        <Grid item xs={10}>
          <CardContent>
            <Typography variant="h6" component="div">
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
};

export default function RestaurantDetail(props) {
  const { state } = useLocation();

  // Use state or directly getTimeSlots(state) depending on how you want to handle state management
  const restaurantTimeSlots = getTimeSlots(state);
  return (
    <>
      <Grid justifyContent={"center"}>
        <Card>
          <Grid container sx={{ backgroundColor: "black" }}>
            <Grid item xl={6} lg={6} md={6} xs={12}>
              <Typography align="left" sx={styles.topBarText}>
                {state.trading?.name}
              </Typography>
            </Grid>
          </Grid>
          <CardMedia
            sx={{
              height: 0,
              paddingTop: "56.25%", // 16:9,
              marginTop: "30",
            }}
            image={state.trading?.images[0]?.image}
          />
          <CardContent>
            <InfoCard logoSrc={IMAGES.foodType} title={state?.trading?.style} />
            <InfoCard
              logoSrc={IMAGES.address}
              title={state?.trading?.address}
            />
            <Card
              sx={{
                display: "flex",
                padding: 2,
                alignItems: "center",
                boxShadow: "none",
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                  <LogoImage src={IMAGES.time} alt="Logo" />
                </Grid>
                <Grid item xs={10}>
                  <CardContent>
                    {restaurantTimeSlots.map((item, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", flexDirection: "row" }}
                      >
                        <Typography sx={styles.daysNameLabel}>
                          {item.days}
                        </Typography>
                        <Typography sx={styles.descriptionLabel}>
                          {item.time}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                </Grid>
              </Grid>
            </Card>
            <InfoCard logoSrc={IMAGES.phone} title={state?.trading?.phone} />
            <InfoCard
              logoSrc={IMAGES.website}
              title={state?.trading?.website}
            />
            <InfoCard
              logoSrc={IMAGES.special}
              title={state?.trading?.specials}
            />
            <InfoCard logoSrc={IMAGES.id} title={state?.trading?.biz_id} />
            <InfoCard logoSrc={IMAGES.id} title={state?.trading?.srl_id} />
            <InfoCard logoSrc={IMAGES.id} title={state?.system_id} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      lg: 25,
      md: 25,
      sm: 28,
      xs: 18,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  innerCardTitleText: {
    fontSize: {
      lg: 28,
      md: 28,
      sm: 22,
      xs: 12,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    paddingRight: {
      lg: 2,
      md: 2,
      sm: 2,
      xs: 1,
    },
  },

  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 22,
      sm: 18,
      xs: 10,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    paddingRight: {
      lg: 2,
      md: 2,
      sm: 2,
      xs: 1.5,
    },
  },
  cardTitileTextBox: {
    backgroundColor: COLOR.GREEN,
    borderRadius: 10,
    justifyContent: "center",
    width: "100%",
    paddingLeft: 10,
    margin: 10,
  },
  yourRankBox: {
    height: { lg: 300, md: 150, sm: 120, xs: 60 },
    width: { lg: 300, md: 150, sm: 120, xs: 60 },
    backgroundColor: COLOR.WHITE,
    marginLeft: 2,
    borderRadius: 2,
  },

  yourRankText: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 44,
      md: 28,
      sm: 22,
      xs: 12,
    },
    fontFamily: "Helvetica Neue",
    paddingTop: {
      lg: 6,
      md: 1,
      sm: 1,
      xs: 0.5,
    },
  },

  giftCardBox: {
    backgroundColor: "#C3DAFF",
    marginLeft: 2,
    borderRadius: 1,
    width: { lg: 500, md: 320, sm: 220, xs: 110 },
    height: { lg: 260, md: 180, sm: 120, xs: 70 },
  },
  giftCardTextRedeemable: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 30,
      md: 28,
      sm: 16,
      xs: 8,
    },
    paddingTop: {
      lg: 4,
      md: 1,
      sm: 1,
      xs: 1,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontFamily: "Helvetica Neue",
  },
  giftCardTextRestaurantName: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 40,
      md: 28,
      sm: 16,
      xs: 8,
    },
    paddingTop: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontWeight: "bold",
    fontFamily: "Helvetica Neue",
  },
  giftCardBottomBox: {
    backgroundColor: COLOR.GP_BLUE,
    width: {
      lg: 500,
      md: 320,
      sm: 220,
      xs: 110,
    },
    height: {
      lg: 70,
      md: 60,
      sm: 44,
      xs: 24,
    },
    borderBottomLeftRadius: {
      lg: 5,
      md: 5,
      sm: 5,
      xs: 2,
    },
    borderBottomRightRadius: {
      lg: 5,
      md: 5,
      sm: 5,
      xs: 2,
    },
    marginTop: {
      lg: 5,
      md: 3,
      sm: 2,
      xs: 1,
    },
  },

  giftCardBottomBoxText: {
    color: COLOR.BLACK,
    fontSize: {
      lg: 22,
      md: 18,
      sm: 15,
      xs: 7.5,
    },
    marginLeft: {
      lg: 2,
      md: 1,
      sm: 1,
      xs: 1,
    },
    fontFamily: "Helvetica Neue",
  },
  topBarText: {
    fontSize: {
      lg: 28,
      md: 22,
      sm: 18,
      xs: 15,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
    margin: 2,
    cursor: "pointer",
  },
  descriptionLabel: {
    fontFamily: "Helvetica Neue",
    fontSize: {
      lg: 28,
      md: 22,
      sm: 18,
      xs: 15,
    },
    color: COLOR.BLACK,
    marginLeft: {
      lg: 28,
      md: 22,
      sm: 3,
      xs: 3,
    },
  },
  daysNameLabel: {
    width: 100,
    fontFamily: "Helvetica Neue",
    fontSize: {
      lg: 28,
      md: 22,
      sm: 18,
      xs: 15,
    },
    fontWeight: "400",
    color: COLOR.BLACK,
  },
};
