import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import CurrentCampaignCard from "../components/CurrentCampaignCard";
import PastCampaignCard from "../components/PastCampaignCard";
import FutureCampaignCard from "../components/FutureCampaignCard";
import API from "../api";
import FlatList from "flatlist-react";
import { getDateFromTimeStamp } from "../commonView/Helpers";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import COLOR from "../assets/js/Color";
import Loader from "../components/Loader";
import NoDataCard from "../components/NoDataCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

let api = new API();

export default function Home() {
  const navigate = useNavigate();
  const [currentCampaignList, setCurrentCampaignList] = useState(null);
  const [pastCampaignList, setPastCampaignList] = useState(null);
  const [futureCampaignList, setFutureCampaignList] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [tabIndex, setTabIndex] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchCampaigns();
  }, [tabIndex]);

  function onCampaignClick(campaignId) {
    navigate("campaignDetail", {
      state: campaignId,
    });
  }

  function fetchCampaigns() {
    setIsLoading(true);
    setCurrentCampaignList(null); // Clear the campaign list before fetching new data
    setPastCampaignList(null);
    setFutureCampaignList(null);
    switch (tabIndex) {
      case 0:
        getAllPastCampaigns();
        break;
      case 1:
        getAllCurrentCampaigns();
        break;
      case 2:
        getAllFutureCampaigns();
        break;
      default:
        break;
    }
  }

  function getAllCurrentCampaigns() {
    api
      .getAllCurrentCampaigns()
      .then((res) => {
        if (res.data.status === 200) {
          setCurrentCampaignList(res.data.response);
        } else {
          console.log(res);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function getAllPastCampaigns() {
    api
      .getAllPastCampaigns()
      .then((res) => {
        if (res.data.status === 200) {
          setPastCampaignList(res.data.response);
        } else {
          console.log(res);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function getAllFutureCampaigns() {
    api
      .getAllFutureCampaigns()
      .then((res) => {
        if (res.data.status === 200) {
          setFutureCampaignList(res.data.response);
        } else {
          console.log(res);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  function clearInput() {
    setSearchInput("");
    fetchCampaigns();
  }

  const RenderPastCampaignCard = (item) => {
    return (
      <Grid item key={item}>
        <PastCampaignCard
          onClick={() => {
            onCampaignClick(item);
          }}
          title={item.trading.name}
          address={item.trading.address}
          startTime={getDateFromTimeStamp(item.startTime, "MMM DD, YYYY ")}
          endTime={getDateFromTimeStamp(item.endTime, "MMM DD, YYYY ")}
          endTimeVisits={getDateFromTimeStamp(
            item.endTime,
            "MMM DD, YYYY hh:mm a"
          )}
          currentVisits={item.currentVisits.toLocaleString()}
          goal={item.goal.toLocaleString()}
          completedPercentage={parseInt(item.currentStatus)}
          bizId={item.trading.biz_id}
          srlId={item.trading.srl_id}
          gpcId={item.system_id}
          status={item.status}
        />
      </Grid>
    );
  };

  const RenderCurrentCampaignCard = (item) => {
    return (
      <Grid lg={4} md={4} item key={item}>
        <CurrentCampaignCard
          onClick={() => {
            onCampaignClick(item);
          }}
          title={item.trading.name}
          address={item.trading.address}
          image={item.trading?.images[0]?.image}
          startTime={getDateFromTimeStamp(item.startTime, "MMM DD, YYYY ")}
          endTime={getDateFromTimeStamp(item.endTime, "MMM DD, YYYY ")}
          currentVisits={item.currentVisits.toLocaleString()}
          goal={item.goal.toLocaleString()}
          completedPercentage={parseInt(item.currentStatus)}
        />
      </Grid>
    );
  };

  const RenderFutureCampaignCard = (item) => {
    return (
      <Grid lg={4} md={4} item key={item}>
        <FutureCampaignCard
          onClick={() => {
            onCampaignClick(item);
          }}
          title={item.trading.name}
          address={item.trading.address}
          image={item.trading?.images[0]?.image}
          startTime={getDateFromTimeStamp(item.startTime, "MMM DD, YYYY ")}
          endTime={getDateFromTimeStamp(item.endTime, "MMM DD, YYYY ")}
          currentVisits={item.currentVisits.toLocaleString()}
          goal={item.goal.toLocaleString()}
          completedPercentage={parseInt(item.currentStatus)}
        />
      </Grid>
    );
  };

  const RenderWhenEmpty = () => {
    if (isLoading) {
      return <Loader visible={isLoading} />;
    }
    if (currentCampaignList?.length === 0) {
      return <NoDataCard title="No Goalprizes Found!" />;
    }
    if (pastCampaignList?.length === 0) {
      return <NoDataCard title="No Goalprizes Found!" />;
    }
    if (futureCampaignList?.length === 0) {
      return <NoDataCard title="No Goalprizes Found!" />;
    }

    return null;
  };

  const searchCampaigns = () => {
    let result = currentCampaignList.filter((o) =>
      o.trading?.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setCurrentCampaignList(result);
  };

  const SearchBar = () => {
    return (
      <>
        <Grid
          container
          direction={"row"}
          alignItems="center"
          justifyContent="center"
          columnSpacing={2}
          rowSpacing={2}
          sx={{
            marginTop: {
              xl: 1,
              lg: 1,
              md: 0,
              sm: 0,
              xs: 0,
            },
            marginBottom: {
              xl: 4,
              lg: 4,
              md: 4,
              sm: 4,
              xs: 0,
            },
          }}
        >
          <Grid item>
            <TextField
              key={"search"}
              id="outlined-name"
              label="Search for Goalprizes"
              defaultValue={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              autoFocus="autoFocus"
              size="small"
            />
          </Grid>
          <Grid item>
            <Button
              startIcon={<SearchIcon />}
              onClick={searchCampaigns}
              variant="contained"
              size="medium"
              sx={{ backgroundColor: COLOR.GP_BLUE }}
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<ClearIcon />}
              onClick={clearInput}
              size="medium"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <>
      <Box style={{ margin: 20 }}>
        <Typography align={"center"} sx={styles.titleText}>
          Find Goalprizes
        </Typography>
        {/* <SearchBar /> */}
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          <Tab sx={styles.tabTitleText} label="Past" {...a11yProps(0)} />
          <Tab sx={styles.tabTitleText} label="Current" {...a11yProps(1)} />
          <Tab sx={styles.tabTitleText} label="Future" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        <Grid
          container
          direction={"column"}
          columnSpacing={3}
          rowSpacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <FlatList
            list={pastCampaignList}
            renderItem={RenderPastCampaignCard}
            renderWhenEmpty={RenderWhenEmpty}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <Grid
          container
          direction={"column"}
          columnSpacing={3}
          rowSpacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <FlatList
            list={currentCampaignList}
            renderItem={RenderCurrentCampaignCard}
            renderWhenEmpty={RenderWhenEmpty}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={tabIndex} index={2}>
        <Grid
          container
          direction={"column"}
          columnSpacing={3}
          rowSpacing={3}
          alignItems="center"
          justifyContent="center"
        >
          <FlatList
            list={futureCampaignList}
            renderItem={RenderFutureCampaignCard}
            renderWhenEmpty={RenderWhenEmpty}
          />
        </Grid>
      </TabPanel>
    </>
  );
}

const styles = {
  titleText: {
    fontSize: {
      lg: 32,
      md: 32,
      sm: 32,
      xs: 24,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
  },
  tabTitleText: {
    fontSize: {
      lg: 22,
      md: 22,
      sm: 22,
      xs: 16,
    },
    fontFamily: "Helvetica Neue",
    color: "black",
  },
};
