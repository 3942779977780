import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Food from "../assets/img/food.jpeg";
import Grid from "@mui/material/Grid";
import ProgressBar from "@ramonak/react-progress-bar";
import Stack from "@mui/material/Stack";
import COLOR from "../assets/js/Color.js";
import Chip from "@mui/material/Chip";

export default function FutureCampaignCard(props) {
  return (
    <>
      <Card sx={styles.mainCard} onClick={props.onClick}>
        <CardHeader
          title={props.title}
          style={{
            backgroundColor: "black",
          }}
          align="left"
          disableTypography={true}
          sx={[
            styles.cardTitleText,
            {
              maxHeight: {
                lg: 100,
                md: 90,
                sm: 80,
                xs: 0,
              },
            },
          ]}
        />
        {/* xs, extra-small: 0px 
          sm, small: 600px 
          md, medium: 900px 
          lg, large: 1200px
          xl, extra-large: 1536px */}
        <CardContent>
          <Grid container>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <CardMedia
                component="img"
                image={props.image}
                sx={styles.restaurantPhoto}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <Stack
                spacing={{ lg: 3, md: 3, sm: 3, xs: 2 }}
                alignItems={"flex-start"}
                sx={{
                  marginTop: {
                    xl: 3,
                    lg: 3,
                    md: 3,
                    sm: 0,
                    xs: 1,
                  },
                  marginLeft: {
                    xl: 3,
                    lg: 3,
                    md: 0,
                    sm: 0,
                    xs: 2.5,
                  } /* marginBottom: { sm: 10 } */,
                }}
              >
                <Typography
                  noWrap
                  sx={[
                    styles.cardBodyText,
                    {
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: {
                        xl: "22rem",
                        lg: "22rem",
                        md: "25rem",
                        sm: "22rem",
                        xs: "10rem",
                      },
                    },
                  ]}
                >
                  {props.address}
                </Typography>
                <Typography sx={styles.cardBodyText}>
                  Start: {props.startTime}
                </Typography>
                <Typography sx={styles.cardBodyText}>
                  End: {props.endTime}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <div style={{ marginTop: 20 }}>
            <ProgressBar
              completed={
                props.completedPercentage > 0 ? props.completedPercentage : "0"
              }
              maxCompleted={100}
              bgColor={"#06C903"}
              // labelSize={styles.progressBarText}
              labelAlignment={"center"}
              height={"40"}
              baseBgColor={"white"}
              animateOnRender={true}
              transitionTimingFunction={"ease-in"}
            />
          </div>
          <Grid container style={{ marginTop: 10, marginBottom: -10 }}>
            <Grid item xs={6}>
              <Typography align="left" sx={styles.cardBodyText}>
                Current:{" "}
                {props.currentVisits < props.goal
                  ? props.currentVisits
                  : props.goal}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" sx={styles.cardBodyText}>
                Goal: {props.goal}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

const styles = {
  cardTitleText: {
    fontSize: {
      xl: 25,
      lg: 25,
      md: 25,
      sm: 25,
      xs: 16,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  cardBodyText: {
    fontSize: {
      lg: 28,
      md: 30,
      sm: 28,
      xs: 14,
    },
    fontFamily: "Helvetica Neue",
    color: "white",
  },

  progressBarText: {
    fontSize: {
      lg: 28,
      md: 30,
      sm: 28,
      xs: 14,
    },
  },

  mainCard: {
    cursor: "pointer",
    backgroundColor: COLOR.GP_BLUE,
  },

  restaurantPhoto: {
    height: {
      lg: 220,
      md: 220,
      sm: 200,
      xs: 110,
    },
    width: { lg: 390, md: 390, sm: 320, xs: 175 },
    borderRadius: 2,
  },
};
